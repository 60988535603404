@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://fonts.googleapis.com/css?family=Google+Sans:200,300,700);

@font-face {
  font-family: 'Logo Font';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/discodeck.830ae793.ttf) format("truetype"); }

body {
  margin: 0;
  font-family: 'Google Sans', sans-serif;
  color: #ffffff;
  overflow: hidden; }

.ip-main {
  width: 100vw;
  height: 100vh; }

.title {
  font-family: "Logo Font", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.5rem; }

.mini-logo {
  flex: 0 0;
  padding: 1rem; }
  .mini-logo:hover {
    cursor: pointer; }

.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  overflow: scroll;
  position: relative; }

.container-none {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
  background: #33333311; }

.footer {
  height: 7rem;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center; }

.header {
  height: 7rem;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10; }
  .header.absolute {
    position: absolute;
    top: 0;
    left: 0; }
  .header.full-width {
    width: 100%; }

.btn {
  background: transparent;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  margin: 0 1rem; }
  .btn.additional {
    margin: 0; }
  .btn.custom-bar-edit {
    border: 1px #fff solid;
    margin-bottom: 1rem; }

.custom-content {
  width: 100%; }

.admin-login, .container-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center; }
  .admin-login .login-panel, .container-wrapper .login-panel {
    border-radius: 5px;
    min-width: 20rem;
    padding: 1rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center; }
    .admin-login .login-panel input, .container-wrapper .login-panel input {
      background: transparent;
      outline: none;
      border: none;
      border-bottom: 2px solid #ffffffaa;
      font-size: 2rem;
      color: #ffffff;
      text-align: center;
      margin: 1rem 0 2rem 0; }
    .admin-login .login-panel input::-webkit-input-placeholder, .container-wrapper .login-panel input::-webkit-input-placeholder {
      color: #ffffffaa; }
    .admin-login .login-panel input::-moz-placeholder, .container-wrapper .login-panel input::-moz-placeholder {
      color: #ffffffaa; }
    .admin-login .login-panel input:-ms-input-placeholder, .container-wrapper .login-panel input:-ms-input-placeholder {
      color: #ffffffaa; }
    .admin-login .login-panel input::-ms-input-placeholder, .container-wrapper .login-panel input::-ms-input-placeholder {
      color: #ffffffaa; }
    .admin-login .login-panel input::placeholder, .container-wrapper .login-panel input::placeholder {
      color: #ffffffaa; }
    .admin-login .login-panel button, .container-wrapper .login-panel button {
      border-radius: 5px;
      background: #fff;
      border: none;
      font-size: 1.5rem;
      padding: 0.5rem 1rem; }

.box {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 2rem; }
  .box .view-box {
    min-width: 15rem;
    max-width: calc(100% - 1rem);
    width: 50rem;
    background: #fff;
    flex: 1 1;
    margin: 2rem 1rem 2rem 1rem;
    box-shadow: #44444444 0.5rem 0.5rem 0.5rem;
    -webkit-transition: all 300ms;
    transition: all 300ms; }
    .box .view-box .inner-view {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 200;
      line-height: 1.5;
      margin: 1rem;
      padding-right: 0;
      color: #333;
      -webkit-transition: all 100ms;
      transition: all 100ms; }
      .box .view-box .inner-view img {
        border-radius: 3px; }
      .box .view-box .inner-view p {
        margin: 0; }
      .box .view-box .inner-view h1, .box .view-box .inner-view h2, .box .view-box .inner-view h3, .box .view-box .inner-view h4 {
        margin: 0.83rem 0 0 0; }
      .box .view-box .inner-view .ql-align-center {
        width: 100%;
        text-align: center; }
      .box .view-box .inner-view .ql-editor {
        height: 60vh;
        max-height: 60vh; }
  .box .view-box:hover {
    height: 100%; }
  .box .absolute-box {
    position: relative;
    min-width: 15rem;
    max-width: 90%;
    width: 40rem;
    margin: 0; }
    .box .absolute-box img {
      width: 100%;
      border-radius: 5px;
      margin: 0;
      padding: 0; }
    .box .absolute-box .title {
      width: calc(100% - 1.99rem);
      background: transparent;
      color: #fff;
      font-family: 'Google Sans', sans-serif;
      letter-spacing: normal;
      position: relative;
      bottom: 0;
      left: 0;
      margin-bottom: 1rem;
      padding: 1rem 1rem;
      border-radius: 5px; }

.admin .admin-section {
  margin: 1rem;
  padding-right: 0;
  color: #333;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  display: flex;
  flex-flow: row;
  justify-content: space-between; }
  .admin .admin-section .title {
    font-size: 1.5rem;
    letter-spacing: 0; }

.admin .inner-view .item {
  margin: 1rem 0;
  min-height: 2rem;
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center; }
  .admin .inner-view .item svg {
    margin: 0 0.5rem; }
  .admin .inner-view .item .item-title {
    font-family: sans-serif; }

.editor .header span.title {
  margin-left: 0.5rem;
  letter-spacing: 0; }

.editor .header button {
  margin: 0; }

.editor .box {
  position: relative; }
  .editor .box .title {
    width: calc(100% - 1.99rem);
    font-size: 1.5rem;
    letter-spacing: 0; }
  .editor .box input.title-input {
    font-family: 'Roboto', cursive;
    font-weight: bold;
    width: 90%;
    color: #333;
    font-size: 2.5rem;
    background: transparent;
    border: none; }
  .editor .box .view-box {
    position: relative; }
  .editor .box .inner-view {
    position: relative; }
    .editor .box .inner-view .buttons {
      display: flex;
      justify-content: space-between; }
      .editor .box .inner-view .buttons button.btn {
        margin: 1rem 0 0 0; }
        .editor .box .inner-view .buttons button.btn svg {
          stroke: #333333; }
    .editor .box .inner-view .additionalOptions {
      margin: 1rem 0; }
      .editor .box .inner-view .additionalOptions input[type="number"] {
        background: transparent;
        border: 1px solid #ccc; }
      .editor .box .inner-view .additionalOptions div {
        margin: 1rem  0 0 0; }
      .editor .box .inner-view .additionalOptions input {
        margin: 0 1rem; }

.img-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: all 300ms;
  transition: all 300ms; }
  .img-modal .wrapper {
    max-width: 90vw;
    width: 40rem;
    min-height: 20rem;
    max-height: 90vh;
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: #44444444 0.5rem 0.5rem 0.5rem;
    display: flex;
    flex-flow: column;
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    color: #333; }
    .img-modal .wrapper .url-modal {
      margin-bottom: 1rem;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      flex: 0 0; }
      .img-modal .wrapper .url-modal input {
        background: transparent;
        border: #cccccc 1px solid;
        padding: 0.5rem;
        border-radius: 5px;
        flex: 1 0;
        margin-right: 1rem; }
    .img-modal .wrapper .select-modal {
      flex: 1 0;
      position: relative;
      margin-bottom: 1rem;
      overflow-y: scroll;
      height: 60vh;
      max-height: 60vh;
      overflow-x: hidden; }
      .img-modal .wrapper .select-modal button {
        position: absolute;
        right: 0;
        bottom: 0; }
      .img-modal .wrapper .select-modal .item {
        max-width: 100%;
        max-height: 100%;
        display: inline-block;
        object-fit: cover;
        height: 5rem;
        width: 5rem;
        border-radius: 3px;
        margin: 0.25rem; }
    .img-modal .wrapper .upload-modal {
      display: flex;
      justify-content: space-between;
      flex: 0 0; }
      .img-modal .wrapper .upload-modal input {
        background: transparent;
        border: #cccccc 1px solid;
        padding: 0.5rem;
        border-radius: 5px;
        flex: 1 0;
        margin-right: 1rem; }
    .img-modal .wrapper button {
      background: transparent;
      border: #cccccc 1px solid;
      padding: 0.5rem;
      border-radius: 5px; }
  .img-modal button {
    background: transparent;
    border: none;
    padding: 0.5rem;
    border-radius: 5px; }

.main {
  background: transparent; }
  .main .container-none {
    background: transparent; }
  .main .intro, .main .about-me, .main .all {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; }
  .main .card {
    background: #f0f0f0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAHklEQVQYV2NkYGAwZmBgOMsAAXA2I1QAg8IrQSWjAI/iBAd6EbYEAAAAAElFTkSuQmCC) repeat;
    width: 85vh;
    height: 60vh;
    padding: 1rem;
    display: flex;
    flex-flow: row;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    -webkit-transform: translateY(0) translateX(0) scale(1);
            transform: translateY(0) translateX(0) scale(1); }
    .main .card:hover {
      box-shadow: #44444444 1rem 1rem 1rem;
      -webkit-transform: translateY(-0.5rem) translateX(-0.5rem) scale(1.02);
              transform: translateY(-0.5rem) translateX(-0.5rem) scale(1.02); }
  .main .next-btn {
    position: absolute;
    top: -10vh;
    background: transparent;
    color: #ffffffaa;
    width: 8rem;
    height: 4rem;
    border: none;
    outline: none; }
    .main .next-btn svg {
      margin-top: -0.5rem; }
  .main .intro {
    position: relative; }
    .main .intro .social {
      width: 100%;
      position: absolute;
      margin-top: -2rem;
      display: flex;
      justify-content: center; }
      .main .intro .social a {
        color: #646464aa;
        margin: 0 0.5rem;
        -webkit-transition: all 300ms;
        transition: all 300ms; }
        .main .intro .social a:hover {
          color: #646464;
          cursor: pointer; }
    .main .intro .greeting {
      display: flex;
      flex-flow: column;
      justify-content: center;
      flex: 0 0;
      width: 40%;
      height: 100%;
      font-family: 'Google Sans', sans-serif;
      font-size: 15vh;
      font-weight: 900;
      color: #646464; }
      .main .intro .greeting div:nth-child(2) {
        margin-top: -6vh; }
    .main .intro .ama {
      position: relative;
      width: 60%;
      height: 100%;
      flex: 1 0; }
      .main .intro .ama .i-ama {
        position: absolute;
        top: 1rem;
        height: 40%;
        width: 100%;
        font-size: 4vh;
        font-family: 'Google Sans', sans-serif;
        color: #646464aa;
        display: flex;
        flex-flow: column;
        align-items: center; }
      .main .intro .ama .occupation {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: #646464aa;
        font-family: 'Google Sans', sans-serif; }
        .main .intro .ama .occupation .select {
          text-align: center;
          font-size: 4vh; }
  .main .featured, .main .all {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: auto; }
    .main .featured .card, .main .all .card {
      width: 50vh;
      height: 10rem;
      margin-top: 2rem; }
      .main .featured .card:first-child, .main .all .card:first-child {
        margin-top: 15vh; }
    @media screen and (max-width: 750px) {
      .main .featured, .main .all {
        margin-bottom: 4rem; } }
    .main .featured .contents, .main .all .contents {
      width: 75vw;
      padding: 0;
      font-size: 0; }
      .main .featured .contents .item, .main .all .contents .item {
        position: relative;
        margin: 0.5rem;
        background-size: cover;
        display: inline-block;
        width: calc(25vw - 1rem);
        height: 20vw;
        -webkit-transition: all 200ms;
        transition: all 200ms; }
        .main .featured .contents .item:hover > .wrapper::before, .main .all .contents .item:hover > .wrapper::before {
          border: 1rem solid; }
        .main .featured .contents .item .wrapper, .main .all .contents .item .wrapper {
          position: relative;
          width: 100%;
          height: 100%; }
          .main .featured .contents .item .wrapper .name, .main .all .contents .item .wrapper .name {
            text-transform: uppercase;
            z-index: 2;
            position: absolute;
            bottom: 0;
            background: #fff;
            color: #333;
            padding: 1rem 2rem;
            text-align: right;
            width: calc(100% - 4rem);
            font-size: 1rem;
            font-family: 'Google Sans', sans-serif;
            -webkit-transition: all 300ms;
            transition: all 300ms; }
          .main .featured .contents .item .wrapper:hover > .name, .main .all .contents .item .wrapper:hover > .name {
            -webkit-transform: translateY(-2rem);
                    transform: translateY(-2rem); }
        .main .featured .contents .item .wrapper::before, .main .all .contents .item .wrapper::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0rem solid;
          -webkit-transition: all 200ms;
          transition: all 200ms;
          box-sizing: border-box;
          content: '';
          z-index: 5; }
        .main .featured .contents .item img, .main .all .contents .item img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      @media screen and (max-width: 1200px) {
        .main .featured .contents, .main .all .contents {
          width: 80vw; }
          .main .featured .contents .item, .main .all .contents .item {
            width: calc(40vw - 1rem);
            height: 30vw; } }
      @media screen and (max-width: 750px) {
        .main .featured .contents, .main .all .contents {
          width: 100vw; }
          .main .featured .contents .item, .main .all .contents .item {
            margin: 0.5rem 1rem;
            width: calc(100vw - 2rem);
            height: 60vw; } }
  .main .about-me {
    position: relative; }
    .main .about-me .next-btn {
      position: absolute;
      bottom: 0;
      background: transparent;
      color: #ffffffaa;
      width: 8rem;
      height: 4rem;
      border: none;
      outline: none; }
      .main .about-me .next-btn svg {
        margin-top: -0.5rem; }
  .main .all .contents {
    margin-top: 0; }
  .main.portrait .card {
    width: 85vw;
    height: 60vw; }
    .main.portrait .card:hover {
      box-shadow: #44444444 0 1rem 1rem;
      -webkit-transform: translateY(-0.5rem) translateX(0) scale(1.02);
              transform: translateY(-0.5rem) translateX(0) scale(1.02); }
  .main.portrait .intro .greeting {
    font-size: 15vw; }
    .main.portrait .intro .greeting div:nth-child(2) {
      margin-top: -6vw; }
  .main.portrait .intro .ama .i-ama {
    font-size: 6vw; }

.ui-rotator {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #646464aa;
  font-family: 'Google Sans', sans-serif;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  margin-bottom: 1rem; }
  .ui-rotator div {
    text-align: center;
    white-space: nowrap;
    opacity: 0; }
  .ui-rotator div:first-child, .ui-rotator div:last-child {
    opacity: 0; }

.card {
  border-radius: 5px;
  background: #fff;
  box-shadow: #44444444 0.5rem 0.5rem 0.5rem; }

.horizontal-combo-select {
  margin-bottom: 1rem;
  flex: 0 0; }
  .horizontal-combo-select.category-combo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 3rem 0;
    width: 80%;
    max-width: 30rem;
    flex: 0 0; }
  .horizontal-combo-select .combo-item {
    background: #1d1c28;
    border: none;
    outline: none;
    color: #a9b7d2;
    border-radius: 0.25rem;
    padding: 0.5rem 0.5rem;
    margin: 0.2rem 0.2rem;
    -webkit-transition: all 300ms;
    transition: all 300ms; }
    .horizontal-combo-select .combo-item:hover {
      background: #38364b; }
    .horizontal-combo-select .combo-item.selected {
      background: #623643; }
    .horizontal-combo-select .combo-item input {
      font-size: 0.8rem;
      min-width: 3rem; }
    .horizontal-combo-select .combo-item .save-btn {
      background: transparent;
      border: none;
      outline: none;
      color: #a9b7d2;
      padding: 0.1rem 0 0.1rem 0.5rem; }

.img-tools {
  background: #fff;
  position: absolute;
  top: 0;
  color: #333;
  font-family: Roboto, sans-serif;
  padding: 0.5rem 0.25rem;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: none; }
  .img-tools button {
    background: #6b6b6b;
    color: #fff;
    border: none;
    padding: 0.25rem 0.5rem;
    margin: 0 0.25rem; }
    .img-tools button.no-bg {
      background: none;
      padding: 0;
      color: #333;
      margin: 0.1rem 0.5rem 0 0; }
  .img-tools input[type=text] {
    width: 5rem;
    margin: 0; }
  .img-tools span {
    margin: 0 0 0 1rem; }

.custom-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.skill-wrapper {
  font-family: 'Google Sans', sans-serif;
  font-weight: normal;
  color: #fff;
  min-width: 15rem;
  max-width: calc(100% - 1rem);
  width: 50rem; }
  .skill-wrapper ul {
    padding: 0;
    margin: 0; }
  .skill-wrapper li {
    font-family: Roboto, sans-serif;
    color: #fff;
    font-weight: 300;
    margin: 0.1rem 1.5rem; }
    .skill-wrapper li.skill-title {
      list-style: none;
      font-family: 'Google Sans', sans-serif;
      font-size: 1.2rem;
      margin: 0 0 0.5rem 0; }
    .skill-wrapper li:last-child {
      margin-bottom: 1rem; }
  .skill-wrapper .col-25 {
    display: flex;
    flex-direction: column; }
  .skill-wrapper .col-50 {
    display: inline-flex;
    flex-direction: column; }

.col-25 {
  width: calc(25% - 2rem/4); }
  @media screen and (max-width: 750px) {
    .col-25 {
      width: calc(100% - 2rem); } }

.col-50 {
  width: calc(50% - 2rem/2); }
  @media screen and (max-width: 750px) {
    .col-50 {
      width: calc(100% - 2rem); } }

