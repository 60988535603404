@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');
//@import url('https://fonts.googleapis.com/css?family=Google+Sans:100,300,400,500,700,900,100i,300i,400i,500i,700i,900i);
@import url('https://fonts.googleapis.com/css?family=Google+Sans:200,300,700');

@font-face {
  font-family: 'Logo Font';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/discodeck.ttf') format('truetype');
}

$font-color: #ffffff;
$font-dark: #646464;
$border-color: #ffffffaa;
$shadow: #44444444 0.5rem 0.5rem 0.5rem;
$shadow-hover: #44444444 1rem 1rem 1rem;

$break-small: 750px;
$break-medium: 1200px;

body {
  margin: 0;
  font-family: 'Google Sans', sans-serif;
  color: $font-color;
  overflow: hidden;
}

.ip-main {
  width: 100vw;
  height: 100vh;
}

.title {
  font-family: "Logo Font", sans-serif;
  font-size: 3rem;
  letter-spacing: 0.5rem;
}

.mini-logo {
  flex: 0 0;
  padding: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  overflow: scroll;
  position: relative;
}


.container-none {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
  background: #33333311;
}

.footer {
  height: 7rem;
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.header {
  height: 7rem;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  //box-shadow: #44444444 0 0.5rem 0.5rem;
  z-index: 10;

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
  }

  &.full-width {
    width: 100%;
  }
}



.btn {
  background: transparent;
  //box-shadow: #44444444 5px 5px 5px;
  border-radius: 5px;
  border: none;
  //border: 2px solid $font-color;
  color: #fff;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  margin: 0 1rem;

  &.additional {
    margin: 0;
  }

  &.custom-bar-edit {
    border: 1px #fff solid;
    margin-bottom: 1rem;
  }
}

.custom-content {
  width: 100%;
}

.admin-login, .container-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .login-panel {
    border-radius: 5px;
    min-width: 20rem;
    padding: 1rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    input {
      background: transparent;
      outline: none;
      border: none;
      border-bottom: 2px solid $border-color;
      font-size: 2rem;
      color: $font-color;
      text-align: center;
      margin: 1rem 0 2rem 0;
    }

    input::placeholder {
      color: $border-color;
    }

    button {
      border-radius: 5px;
      background: #fff;
      border: none;
      font-size: 1.5rem;
      padding: 0.5rem 1rem;
    }
  }
}


.box {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 2rem;

  .view-box {
    min-width: 15rem;
    max-width: calc(100% - 1rem);
    width: 50rem;
    //background: #f0f0f0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAHklEQVQYV2NkYGAwZmBgOMsAAXA2I1QAg8IrQSWjAI/iBAd6EbYEAAAAAElFTkSuQmCC) repeat;
    background: #fff;
    //border-radius: 5px;
    flex: 1 1;
    margin: 2rem 1rem 2rem 1rem;
    box-shadow: $shadow;
    transition: all 300ms;

    .inner-view {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 200;
      line-height: 1.5;
      margin: 1rem;
      padding-right: 0;
      color: #333;
      transition: all 100ms;

      img {
        border-radius: 3px;
      }

      p {
        margin: 0;
      }

      h1, h2, h3, h4 {
        margin: 0.83rem 0 0 0;
      }

       .ql-align-center {
         width: 100%;
         text-align: center;
       }
      .ql-editor {
        height: 60vh;
        max-height: 60vh;
      }
    }
  }

  .view-box:hover {
    height: 100%;
  }

  .absolute-box {
    position: relative;
    //min-height: 20rem;
    min-width: 15rem;
    max-width: 90%;
    width: 40rem;
    margin: 0;

    img {
      width: 100%;
      border-radius: 5px;
      margin: 0;
      padding: 0;
    }
    //border: 1px solid #fff;

    .title {
      width: calc(100% - 1.99rem);
      //background:#fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAHklEQVQYV2NkYGAwZmBgOMsAAXA2I1QAg8IrQSWjAI/iBAd6EbYEAAAAAElFTkSuQmCC) repeat;
      background: transparent;
      color: #fff;
      font-family: 'Google Sans', sans-serif;
      letter-spacing: normal;
      position: relative;
      bottom: 0;
      left: 0;
      margin-bottom: 1rem;
      padding: 1rem 1rem;
      border-radius: 5px;
    }
  }
}

.admin {
  .admin-section {
    margin: 1rem;
    padding-right: 0;
    color: #333;
    transition: all 100ms;
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .title {
      font-size: 1.5rem;
      letter-spacing: 0;
    }
  }

  .inner-view {
    .item {
      margin: 1rem 0;
      min-height: 2rem;
      display: flex;
      flex-flow: row;
      width: 100%;
      align-items: center;

      svg {
        margin: 0 0.5rem;
      }

      .item-title {
        font-family: sans-serif;
      }
    }
  }
}

.editor {
  .header {
    span.title {
      margin-left: 0.5rem;
      letter-spacing: 0;
    }
    button {
      margin: 0;
    }
  }
  .box {
    position: relative;
    .title {
      width: calc(100% - 1.99rem);
      font-size: 1.5rem;
      letter-spacing: 0;
    }

    input.title-input {
      font-family: 'Roboto', cursive;
      font-weight: bold;
      width: 90%;
      color: #333;
      font-size: 2.5rem;
      background: transparent;
      border: none;
      //border-bottom: 1px solid $font-color;
    }
    .view-box {
      position: relative;
    }
    .inner-view {
      position: relative;
      .buttons {
        display: flex;
        justify-content: space-between;
        button.btn {
          margin: 1rem 0 0 0;
          svg {
            stroke: #333333;
          }
        }
      }
      .additionalOptions {
        margin: 1rem 0;

        input[type="number"] {
          background: transparent;
          border: 1px solid #ccc;
        }
        div {
          margin: 1rem  0 0 0;
        }

        input {
          margin: 0 1rem;
        }
      }
    }
  }
}

.img-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
  transition: all 300ms;

  .wrapper {
    max-width: 90vw;
    width: 40rem;
    min-height: 20rem;
    max-height: 90vh;
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: $shadow;
    display: flex;
    flex-flow: column;
    opacity: 0;
    transition: all 300ms;
    color: #333;

    .url-modal {
      margin-bottom: 1rem;
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      flex: 0 0;

      input {
        background: transparent;
        border: #cccccc 1px solid;
        padding: 0.5rem;
        border-radius: 5px;
        flex: 1 0;
        margin-right: 1rem;
      }
    }

    .select-modal {
      flex: 1 0;
      position: relative;
      margin-bottom: 1rem;
      overflow-y: scroll;
      height: 60vh;
      max-height: 60vh;
      overflow-x: hidden;

      button {
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .item {
        max-width:100%;
        max-height:100%;
        display: inline-block;
        object-fit: cover;
        height: 5rem;
        width: 5rem;
        border-radius: 3px;
        margin: 0.25rem;
      }
    }

    .upload-modal {
      display: flex;
      justify-content: space-between;
      flex: 0 0;

      input {
        background: transparent;
        border: #cccccc 1px solid;
        padding: 0.5rem;
        border-radius: 5px;
        flex: 1 0;
        margin-right: 1rem;
      }
    }

    button {
      background: transparent;
      border: #cccccc 1px solid;
      padding: 0.5rem;
      border-radius: 5px;
    }
  }

  button {
    background: transparent;
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
  }
}

.main {
  background: transparent;
  $c: &;

  .container-none {
    background: transparent;
  }

  .intro, .about-me, .all {
    //border: 1px solid #f0f0f0;
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    background: #f0f0f0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAHklEQVQYV2NkYGAwZmBgOMsAAXA2I1QAg8IrQSWjAI/iBAd6EbYEAAAAAElFTkSuQmCC) repeat;
    width: 85vh;
    height: 60vh;
    padding: 1rem;
    display: flex;
    flex-flow: row;

    transition: all 300ms;
    transform: translateY(0) translateX(0) scale(1);

    &:hover {
      box-shadow: $shadow-hover;
      transform: translateY(-0.5rem) translateX(-0.5rem) scale(1.02);
    }
  }

  .next-btn {
    position: absolute;
    top: -10vh;
    background: transparent;
    color: $font-color + aa;
    width: 8rem;
    height: 4rem;
    border: none;
    outline: none;

    svg {
      margin-top: -0.5rem;
    }
  }

  .intro {
    position: relative;

    .social {
      width: 100%;
      position: absolute;
      margin-top: -2rem;
      display: flex;
      justify-content: center;

      a {
        color: $font-dark + aa;
        margin: 0 0.5rem;
        transition: all 300ms;

        &:hover {
          color: $font-dark;
          cursor: pointer;
        }
      }
    }
    .greeting {
      display: flex;
      flex-flow: column;
      justify-content: center;
      flex: 0 0;
      width: 40%;
      height: 100%;
      font-family: 'Google Sans', sans-serif;
      font-size: 15vh;
      font-weight: 900;
      color: $font-dark;

      div:nth-child(1) {

      }
      div:nth-child(2) {
        margin-top: -6vh;
      }
    }

    .ama {
      position: relative;
      width: 60%;
      height: 100%;
      flex: 1 0;

      .i-ama {
        position: absolute;
        top: 1rem;
        height: 40%;
        width: 100%;
        font-size: 4vh;
        font-family: 'Google Sans', sans-serif;
        color: $font-dark + aa;
        display: flex;
        flex-flow: column;
        align-items: center;
        //justify-content: center;
      }

      .occupation {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: $font-dark + aa;
        font-family: 'Google Sans', sans-serif;

        .select {
          text-align: center;
          font-size: 4vh;
        }
      }
    }
  }
  .featured, .all {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: auto;
    .card {
      width: 50vh;
      height: 10rem;
      margin-top: 2rem;

      &:first-child {
        margin-top: 15vh;
      }
    }

    @media screen and (max-width: $break-small) {
      margin-bottom: 4rem;
    }
    .contents {
      width: 75vw;
      //margin-top: 15vh;
      padding: 0;
      font-size: 0;
      .item {
        position: relative;
        margin: 0.5rem;
        background-size: cover;
        display: inline-block;
        width: calc(25vw - 1rem);
        height: 20vw;
        transition: all 200ms;

        &:hover > .wrapper::before {
          border: 1rem solid;
        }

        .wrapper{
          position: relative;
          width: 100%;
          height: 100%;

          .name {
            text-transform: uppercase;
            z-index: 2;
            position: absolute;
            bottom: 0;
            background: #fff;
            color: #333;
            padding: 1rem 2rem;
            text-align: right;
            width: calc(100% - 4rem);
            font-size: 1rem;
            font-family: 'Google Sans', sans-serif;
            transition: all 300ms;
          }
          &:hover > .name {
            transform: translateY(-2rem);
          }
        }
        .wrapper::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0rem solid;
          transition: all 200ms;
          box-sizing: border-box;
          content: '';
          z-index: 5;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @media screen and (max-width: $break-medium) {
        width: 80vw;

        .item {
          width: calc(40vw - 1rem);
          height: 30vw;
        }
      }

      @media screen and (max-width: $break-small) {
        width: 100vw;

        .item {
          margin: 0.5rem 1rem;
          width: calc(100vw - 2rem);
          height: 60vw;
        }
      }
    }
  }

  .about-me {
    position: relative;

    .next-btn {
      position: absolute;
      bottom: 0;
      background: transparent;
      color: $font-color + aa;
      width: 8rem;
      height: 4rem;
      border: none;
      outline: none;

      svg {
        margin-top: -0.5rem;
      }
    }
  }

  .all {
    .contents {
      margin-top: 0;
    }
  }

  &.portrait {
    .card {
      width: 85vw;
      height: 60vw;

      &:hover {
        box-shadow: #44444444 0 1rem 1rem;;
        transform: translateY(-0.5rem) translateX(0) scale(1.02);
      }
    }
    .intro {
      .greeting {
        font-size: 15vw;

        div:nth-child(2) {
          margin-top: -6vw;
        }
      }

      .ama {
        .i-ama {
          font-size: 6vw;
        }
      }
    }
  }
}

.ui-rotator {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: $font-dark + aa;
  font-family: 'Google Sans', sans-serif;
  transition: all 300ms;
  margin-bottom: 1rem;
  div {
    text-align: center;
    white-space: nowrap;
    opacity: 0;
  }

  div:first-child, div:last-child {
    opacity: 0;
  }
}

.card {
  border-radius: 5px;
  background: #fff;
  box-shadow: $shadow;
}

.horizontal-combo-select {
  margin-bottom: 1rem;
  flex: 0 0;

  &.category-combo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 3rem 0;
    width: 80%;
    max-width: 30rem;
    flex: 0 0;
  }

  .combo-item {
    background: #1d1c28;
    border: none;
    outline: none;
    color: #a9b7d2;
    border-radius: 0.25rem;
    padding: 0.5rem 0.5rem;
    margin: 0.2rem 0.2rem;
    transition: all 300ms;

    &:hover {
      background: #38364b;
    }

    &.selected {
      background: #623643;
    }

    input {
      font-size: 0.8rem;
      min-width: 3rem;
    }

    .save-btn {
      background: transparent;
      border: none;
      outline: none;
      color: #a9b7d2;
      padding: 0.1rem 0 0.1rem 0.5rem;
    }
  }
}

.img-tools {
  background: #fff;
  //border: 0.2rem solid #6b6b6b;
  position: absolute;
  top: 0;
  color: #333;
  font-family: Roboto, sans-serif;
  //border-radius: 5px;
  //box-shadow: $shadow;
  padding: 0.5rem 0.25rem;
  z-index: 1000;
  //display: flex;
  justify-content: center;
  align-items: center;
  display: none;

  button {
    background: #6b6b6b;
    color: #fff;
    border: none;
    //border-radius: 2px;
    padding: 0.25rem 0.5rem;
    margin: 0 0.25rem;

    &.no-bg {
      background: none;
      padding: 0;
      color: #333;
      margin: 0.1rem 0.5rem 0 0;
    }
  }

  input[type=text] {
    width: 5rem;
    margin: 0;
  }
  span {
    margin: 0 0 0 1rem;
  }
}

.custom-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skill-wrapper {
  font-family: 'Google Sans', sans-serif;
  font-weight: normal;
  color: #fff;
  min-width: 15rem;
  max-width: calc(100% - 1rem);
  width: 50rem;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    font-family: Roboto, sans-serif;
    color: #fff;
    font-weight: 300;
    margin: 0.1rem 1.5rem;

    &.skill-title {
      list-style: none;
      font-family: 'Google Sans', sans-serif;
      font-size: 1.2rem;
      margin: 0 0 0.5rem 0;
    }

    &:last-child {
      margin-bottom: 1rem;
    }
  }

  .col-25 {
    display: flex;
    flex-direction: column;
  }

  .col-50 {
    display: inline-flex;
    flex-direction: column;
  }

}

.col-25 {
  width: calc(25% - 2rem/4);

  @media screen and (max-width: $break-small) {
    width: calc(100% - 2rem);
  }
}

.col-50 {
  width: calc(50% - 2rem/2);

  @media screen and (max-width: $break-small) {
    width: calc(100% - 2rem);
  }
}